@import "./src/assets/theme/vars.scss";
body.theme-yukka,
body.theme-dark,
body.theme-expo,
body.theme-ubs {
  background-color: $black;
  .news-rout {
    background-color: rgba(255, 255, 255, 0.02) !important;
  }
  .edit-button {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  .tab-divider {
    border-right: 1px solid rgba(255, 255, 255, 0.12);
  }
  .collections-editor {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    //background-color: rgba(0, 0, 0, 0.1);
    &.active {
      //background-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(150, 150, 150, 0.12);
    }
    &:hover {
      //background-color: rgba(0, 0, 0, 0.2);
      background-color: rgba(150, 150, 150, 0.12);
    }
  }
  .folio-entity {
    .no-color {
      color: rgb(204, 204, 204);
    }
    .box-table {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }
    &.default {
      //.titolo {
      //  border-bottom: 1px solid rgb(204, 204, 204);
      //}
      .box-table {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
        color: rgb(204, 204, 204) !important;
      }
    }
  }
  .treemap-template {
    .text {
      color: black !important;
    }
  }
  .basecolor {
    color: white !important;
  }
  .news-treemap {
    .back {
      display: inherit !important;
    }
    .box-map {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }
  .chart-title:not(.noback) {
    color: black !important;
  }
  .chart-title.noback {
    color: white !important;
  }
  .main-news-cont-bot {
    border: none;
  }
  .trending-main {
    .box-trend {
      color: white !important;
      &:hover {
        background-color: auto !important;
        opacity: 0.8 !important;
      }
    }
  }
  .mat-autocomplete-panel {
    &.distinct-panel {
      background-color: rgb(80, 80, 80) !important;
    }
  }
  .mat-autocomplete-visible {
    &.distinct-panel {
      background-color: rgb(80, 80, 80) !important;
    }
  }
  .letter-form {
    .form-fildo {
      &.multiple {
        border: 1px solid rgba(255, 255, 255, 0.3);
      }
    }
  }
  .newsletter-page {
    .mat-select-arrow {
      color: white;
    }
    .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
      color: white;
    }
  }
}

@import './src/assets/theme/vars.scss';
body.theme-stage {
  background-color: $black;
  .mainmenu.active,
  .mat-icon-button.active {
    color: $yukkacolor;

    &.light {
      color: $yukkacolorLight;
    }
  }
  .news-rout {
    background-color: rgba(255, 255, 255, 0.02) !important;
  }
  .treemap-template {
    .text {
      color: black !important;
    }
  }
  .basecolor {
    color: white !important;
  }
  .news-treemap {
    .back {
      display: inherit !important;
    }
    .box-map {
      color: rgba(0, 0, 0, 0.8) !important;
    }
  }
  .chart-title:not(.noback) {
    color: black !important;
  }
  .main-news-cont-bot {
    border: none;
  }
  .trending-main {
    .box-trend {
      color: rgba(0, 0, 0, 0.8) !important;
      &:hover {
        background-color: auto !important;
        opacity: 0.8 !important;
      }
    }
  }
}

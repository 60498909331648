@use '@angular/material' as mat;
mat.$theme-ignore-duplication-warnings: true;
@import 'assets/theme/vars.scss';
@import 'assets/theme/theme-yukka.scss';
@import 'assets/theme/theme-stage.scss';
@import 'assets/theme/theme-light.scss';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();
$primary: mat.define-palette(mat.$grey-palette);
$accent: mat.define-palette(mat.$grey-palette);
$warn: mat.define-palette(mat.$grey-palette);
$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);
@include mat.all-legacy-component-themes($dark-theme);
$theme-dark-back: #2c2c2c;
$theme-dark-text: #cccccc;
$colortest: #000000;

body {
  margin: 0;
  overflow: hidden;
  font-family: 'RobotoDraft', 'Roboto', 'Helvetica Neue, Helvetica, Arial',
    sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  height: 100vh;
  color: $theme-dark-text;

  &.theme-dark {
    @include mat.all-legacy-component-colors($dark-theme);
  }
  &.theme-light {
    @include mat.all-legacy-component-colors($light-theme);
  }

  .tutorial-colors {
    height: 50px;
    background: linear-gradient(to left, #3dac00, #f6d500, #dd001a);
  }

  a {
    color: $theme-dark-text !important;
  }

  .news-link-row > a {
    color: unset !important;
  }
  .news-metadata-row > a {
    color: unset !important;
  }

  .mat-menu-panel {
    min-height: 0px;
    max-width: none;
  }

  .inptfile {
    position: absolute;
    height: 0;
    width: 0;
  }
  .mat-optgroup .mat-option {
    padding-left: 10px !important;
  }

  .ioselect {
    .mat-select-value {
      display: flex;
      max-width: 100%;
      padding-right: 10px;
    }
  }

  .chart-load {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    //   .mat-spinner {
    //     opacity: 0.5;
    //   }
  }

  .chart-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //z-index: 1;
    padding: 0px 10px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.05);
    .title {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      margin-left: 5px;
      justify-content: flex-end;
      width: 100%;
      .text {
        display: flex;
        align-items: center;
        margin-right: auto;
        overflow: hidden;
        span {
          margin-right: 5px;
        }
        .tipo {
          font-weight: lighter;
          opacity: 0.7;
        }
        .chart-home {
          display: none;
        }
      }
      .trendicon {
        margin-left: 5px;
        font-size: 12px;
        font-weight: normal;
      }
      .percent {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
      }
      .submenu {
        z-index: 1;
        margin-right: 5px;
        display: flex;
        .mat-icon {
          margin-left: 10px;
          font-size: 18px;
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }

  .mat-checkbox-checked .mat-checkbox-background {
    // background-color: #94d528 !important;
    background-color: $yukkacolor !important;
  }
  .mat-pseudo-checkbox-checked {
    // background-color: #94d528 !important;
    background-color: $yukkacolor !important;
  }
  //.mat-checkbox-checked .mat-checkbox-background {
  //  background-color: transparent !important;
  //}
  .mat-radio-inner-circle {
    // background-color: #94d500 !important;
    background-color: $yukkacolor !important;
  }
  .mat-radio-checked .mat-radio-outer-circle {
    // border-color: #94d500 !important;
    background-color: $yukkacolor !important;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    // background-color: #94d500 !important;
    // background-color: $yukkacolorLight !important;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    // background-color: #7cb300 !important;
    background-color: $yukkacolor !important;
  }
  .mat-slider-thumb {
    background-color: $yukkacolor !important;
  }

  .mat-ink-bar {
    background-color: $yukkacolor !important;
  }

  @media (max-width: 900px) {
    .filterSearch {
      width: auto;
      position: fixed;
      left: 6px;
      right: 6px;
      top: 58px;
    }
    .viewnews .infomain .main-blok {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    .main-news-cont-bot {
      display: none !important;
    }
    .ismobile {
      display: inline !important;
    }
  }
  @media (max-width: 700px) {
    .menu-search {
      .iconsearch {
        display: none !important;
      }
      .srcicon {
        display: inherit !important;
      }
    }
    .navlogo {
      height: 20px;
    }
    .menu-above-rigt .filter,
    .inptrage,
    .daysenty {
      display: none !important;
    }
    .mat-dialog-content {
      &.newscontent {
        max-height: 100%;
      }
      margin: 0px;
    }
    .panelNews {
      padding: 0;
      max-width: 100% !important;
      position: absolute !important;
      left: 10px;
      right: 10px;
      top: 10px;
      bottom: 10px;
      .mat-dialog-container {
        padding: 0;
        overflow-x: hidden;
      }
    }
  }
  @media (max-width: 400px) {
    .menu-search {
      // display: none !important;
    }
  }

  .fullscreen {
    .ismobile {
      display: inline !important;
    }
  }
  .ismobile {
    display: none;
  }
  .invisible {
    opacity: 0;
    height: 0;
    width: 0;
  }
  .doto {
    margin: 0 10px;
  }
  .mat-icon {
    width: auto;
    height: auto;
  }
  .pointer {
    cursor: pointer;
  }
  .yukkapp,
  .loading {
    background-color: $yukkapp;
  }
  .mat-icon.flip {
    transform: scaleX(-1);
  }
  .hide,
  .hidden {
    display: none !important;
  }
  .show {
    display: inherit !important;
  }
  .menu-above,
  .menu-aside,
  .menu-bside {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .mat-button.active,
  .mat-icon-button.active,
  .mat-icon.active,
  .mainmenu.active {
    &:not(.active-disabled) {
      color: $yukkacolor !important;
    }
    &.light {
      color: $yukkacolorLight !important;
    }
  }
  .btnact {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  .news-entity,
  .treemap-template,
  .main-portfolio {
    color: #252424 !important;
  }
  ::-webkit-scrollbar {
    background-color: #3a3a3a;
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #555555;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: $theme-dark-text !important;
    box-shadow: 0 0 0 30px $theme-dark-back inset;
    -webkit-box-shadow: 0 0 0 30px $theme-dark-back inset;
  }
  .mat-snack-bar-container {
    background-color: #191919 !important;
    &.red-snackbar {
      background-color: $negative !important;
    }
    color: white !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .btnyukka {
    cursor: pointer;
    &:hover {
      color: $yukkacolor;
    }
  }
  *:focus {
    outline: 0;
  }
  .mat-tab-links {
    display: flex !important;
    justify-content: space-around !important;
    .mat-tab-link {
      flex: 1 !important;
      min-width: 0px;
      height: 40px;
      opacity: 1;
      display: flex;
      justify-content: flex-start;
      border-radius: 5px;
      &:focus {
        //background-color: transparent !important;
        background-color: rgba(150, 150, 150, 0.06) !important;
      }
    }
  }
  .mat-tab-labels {
    display: flex !important;
    justify-content: space-around !important;
    .mat-tab-label {
      min-width: 0 !important;
      flex: 1 !important;
      height: 40px;
    }
  }
  .mat-tab-label-active {
    background-color: rgba(150, 150, 150, 0.12) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    //box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
  .mat-tab-header-pagination {
    box-shadow: none !important;
  }
  .nodata {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    //&.button-middle {
    //  padding-top: 100px;
    //}
    font-size: 13px !important;
    font-weight: bold;
    color: silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.button-end {
      justify-content: flex-end;
      .mat-stroked-button {
        margin-left: 10px;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        color: white;
        background-color: $yukkacolor;
      }
      .mat-icon {
        font-size: 18px;
        margin-bottom: 2px;
      }
    }
    &.relative {
      position: relative;
      padding: 30px 0px;
    }
    .text {
      max-width: 400px;
      text-align: center;
    }
  }
  .highlight {
    background-color: #949494;
    //padding: 2px 5px;
    //font-weight: bold;
    border-radius: 2px;
    text-shadow: 0 0 1px;
    color: white !important;
    box-decoration-break: clone;
    -webkit-box-decoration-break: slice;
    &.pos {
      background-color: $positive;
      box-shadow: 0 0 2px 2px $positive;
    }
    &.neu {
      background-color: $neutrale;
      box-shadow: 0 0 2px 2px $neutrale;
    }
    &.neg {
      background-color: $negative;
      box-shadow: 0 0 2px 2px $negative;
    }
    &.null {
      background-color: $null;
      box-shadow: 0 0 2px 2px $null;
    }
    &.undefined {
      background-color: $null;
      box-shadow: 0 0 2px 2px $null;
    }
    &.title {
      background-color: transparent !important;
      padding: 0 !important;
      font-weight: bold !important;
      border-radius: 0 !important;
      color: $yukkacolor1 !important;
    }
  }
  .inblur {
    filter: blur(6px);
    pointer-events: none;
  }
  .deactivated {
    pointer-events: none;
  }
  .noblur {
    border: 2px solid white !important;
    border-radius: 5px;
    filter: blur(0px);
    z-index: 900;
  }
  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 998;
    &.login {
      background-color: transparent !important;
      position: relative;
      margin: 10px 10px 0px 20px;
      width: auto;
      height: auto;
    }
    .mat-spinner {
      opacity: 0.5;
    }
    &.relative {
      position: relative;
    }
  }
  .mat-tab-label:focus {
    //background-color: transparent !important;
    background-color: rgba(150, 150, 150, 0.06) !important;
  }
  .beta {
    font-weight: lighter;
    opacity: 0.7;
    margin-left: 5px;
  }
  .legend-items .legend-item:hover {
    color: #ffffff !important;
  }
  .factuality-fact2 {
    //background-color: #334CDD !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-probable2 {
    //background-color: #6176E5 !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-possible2 {
    //background-color: #A49AEB !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-na2 {
    background-color: #d3ccf5 !important;
  }
  .temporality-past2 {
    //background-color: #417C9D !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-present2 {
    //background-color: #6DB9AC !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-future2 {
    //background-color: #AED7D3 !important;
    background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-na2 {
    background-color: #d8eae9 !important;
  }
  .factuality-fact {
    background-color: #334cdd !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-probable {
    background-color: #6176e5 !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-possible {
    background-color: #a49aeb !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .factuality-na {
    background-color: #d3ccf5 !important;
  }
  .temporality-past {
    background-color: #417c9d !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-present {
    background-color: #6db9ac !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-future {
    background-color: #aed7d3 !important;
    //background-color: transparent !important;
    border: solid 1px;
  }
  .temporality-na {
    background-color: #d8eae9 !important;
  }
}
.backFilter {
  padding: 0;
}
.mat-menu-content {
  padding: 0 !important;
  max-height: 300px;
}

.filterSearch {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  margin: 1px -1px;
  max-height: 80vh !important;
  .mat-optgroup-label {
    opacity: 0.5;
    //zoom: 0.9;
    font-size: 12px;
    // transform: scale(0.9);
    // transform-origin: 0 0;
  }
  .mat-option {
    padding: 0px 10px;
  }
  .search-indexo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    .indexo-left {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: bold;
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .match {
        font-weight: lighter;
        margin-left: 10px;
        color: silver;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .subsidiary {
        margin-left: 3px;
        padding-left: 10px;
        border-left: 2px solid rgba(120, 120, 120, 0.5);
      }
    }
    .indexo-right {
      width: 160px;
      //margin-left: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: silver;
      //justify-content: baseline;
      .trendicon {
        font-size: 11px;
        font-weight: lighter;
        margin-left: 5px;
      }
      .optgroup-column {
        &.column-0 {
          width: 30px;
        }
        &.column-1 {
          width: 50px;
        }
        margin-left: 10px;
      }
    }
  }
}

.searchLetter {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  .mat-optgroup-label {
    opacity: 0.5;
    //zoom: 0.9;
    font-size: 12px;
    //transform: scale(0.9);
    //transform-origin: 0 0;
  }
  .mat-option {
    padding: 0px 10px;
  }
  .search-indexo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: 14px;
    .indexo-left {
      flex: 1;
      overflow: hidden;
      .match {
        font-weight: lighter;
        margin-left: 10px;
        // color: silver;
      }
    }
    .indexo-right {
      margin-left: 10px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: baseline;
      .trendicon {
        font-size: 11px;
        font-weight: lighter;
        margin-left: 5px;
      }
    }
  }
}

.menu-aside {
  .mat-badge-content {
    width: 12px !important;
    height: 12px !important;
    left: -7px !important;
    top: -7px !important;
    line-height: 12px !important;
    background-color: #008b8b !important;
    font-size: 10px !important;
  }
}

.mat-drawer-side {
  border: none;
}
.highcharts-selection-marker {
  fill: rgb(0, 0, 0) !important;
  opacity: 0.1;
}

.highcharts-menu {
  background-color: #424242 !important;
  box-shadow: none !important;
  border: none !important;
  hr {
    display: none !important;
  }
  .highcharts-menu-item {
    &:hover {
      background-color: #353535 !important;
    }
  }
}
.highcharts-exporting-group {
  opacity: 0.5;
  cursor: pointer;
}
.highcharts-contextmenu {
  .highcharts-menu {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .highcharts-menu-item {
    background-color: transparent;
    color: white !important;
  }
}

.inptrage {
  display: flex;
  align-items: center;
  font-weight: normal;
  background-color: transparent;
  position: relative;
  font-size: 14px;
  input {
    width: 34px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 2px 0px;
    margin: 0px 5px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.05);
    //padding-right: 15px;
    font-weight: bold;
  }
  .icon {
    position: absolute;
    right: 7px;
    font-size: 15px !important;
  }
}

.search-panel {
  max-width: calc(100px + 50vw) !important;
}

$positive: #3dac00;
$neutrale: #f6d500;
$negative: #dd001a;
$warning: #ff9900;
$null: #a3a3a3;
$border1: 1px solid rgba(0, 0, 0, 0.2);
$border2: 2px solid rgba(0, 0, 0, 0.2);
$border12: 1px solid rgba(255, 255, 255, 0.2);
$greydark: #444;
$greylight: #ddd;
$active: rgba(0, 0, 0, 0.2);
$yukkacolor1: #1e816c;
$yukkacolor: #6ff3c4;
$yukkacolorLight: #488f76;
$yukkapp: #363636;
$black: #2c2c2c;

$test: rgba(60, 172, 0, 0.2);
